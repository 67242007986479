import classNames from 'classnames';
import { bool, func, object, oneOfType, string } from 'prop-types';
import React from 'react';

import { useConfiguration } from '../../context/configurationContext';

import { isBookingProcessAlias } from '../../transactions/transaction';
import { displayPrice } from '../../util/configHelpers';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { propTypes } from '../../util/types';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { createSlug } from '../../util/urlHelpers';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';
import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const CustomListingCard = props => {
  const {
    aspectWidth,
    aspectHeight,
    setActivePropsMaybe,
    title,
    firstImage,
    variants,
    renderSizes,
    price,
    publicData,
    config,
    intl,
    authorName,
    isProfilePage,
    setInquiryModalOpen,
    showAuthorInfo,
    id,
    onSubmit,
    slug,
    routeConfiguration,
    currentUser,
  } = props;
  const history = useHistory();
  const location = useLocation();
  return (
    <div
      onClick={() => {
        if (isProfilePage) {
          if (!currentUser) {
            const state = { from: `${location.pathname}${location.search}${location.hash}` };
            history.push(
              createResourceLocatorString('SignupPage', routeConfiguration, {}, {}),
              state
            );
          } else {
            setInquiryModalOpen(true);

            onSubmit({
              id,
              title,
              slug,
              authorName,
            });
          }
        }
      }}
    >
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </div>
        <div className={css.mainInfo}>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
        </div>
        <PriceMaybe className={css.priceText} price={price} publicData={publicData} config={config} intl={intl} />
      </div>
      {isProfilePage ? (
        <div className={css.enquireButton}>
          <FormattedMessage id="ListingCard.profilePage.enquire" />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    isProfilePage,
    setInquiryModalOpen,
    inquiryModalOpen,
    onManageDisableScrolling,
    onSendInquiry,
    getListing,
    sendInquiryInProgress,
    sendInquiryError,
    onSubmit,
    currentUser,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  return (
    // <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
    <div>
      {!isProfilePage ? (
        <NamedLink className={classes} name="ProfilePage" params={{ id: author.id.uuid }}>
          <CustomListingCard
            aspectWidth={aspectWidth}
            aspectHeight={aspectHeight}
            setActivePropsMaybe={setActivePropsMaybe}
            title={title}
            firstImage={firstImage}
            variants={variants}
            renderSizes={renderSizes}
            price={price}
            publicData={publicData}
            config={config}
            intl={intl}
            showAuthorInfo={showAuthorInfo}
            authorName={authorName}
            isProfilePage={isProfilePage}
            setInquiryModalOpen={setInquiryModalOpen}
            id={id}
          />
        </NamedLink>
      ) : (
        <CustomListingCard
          aspectWidth={aspectWidth}
          aspectHeight={aspectHeight}
          setActivePropsMaybe={setActivePropsMaybe}
          title={title}
          firstImage={firstImage}
          variants={variants}
          renderSizes={renderSizes}
          price={price}
          publicData={publicData}
          config={config}
          intl={intl}
          showAuthorInfo={showAuthorInfo}
          authorName={authorName}
          isProfilePage={isProfilePage}
          setInquiryModalOpen={setInquiryModalOpen}
          id={id}
          onSubmit={onSubmit}
          slug={slug}
          routeConfiguration={routeConfiguration}
          currentUser={currentUser}
        />
      )}
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
  isProfilePage: false,
  inquiryModalOpen: false,
  sendInquiryInProgress: false,
  sendInquiryError: null,
  currentUser: null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  showAuthorInfo: bool,
  isProfilePage: bool,
  inquiryModalOpen: bool,
  setInquiryModalOpen: func,
  onManageDisableScrolling: func,
  onSendInquiry: func,
  getListing: func,
  sendInquiryInProgress: bool,
  sendInquiryError: object,
  onSubmit: func,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
  currentUser: object,
};

export default injectIntl(ListingCardComponent);
